<template>
  <div
    class="benefits__section3__video"
    @click="onClickOutside"
    id="Benefitsection3"
  >
    <button class="btn benefits__section3__playbtn" @click="onClick">
      <img class="img-fluid" :src="play" alt="play button" />
      <p class="benefits__section3__playbtn__text">
        {{ $t("benefits.videotext") }}
      </p>
    </button>
    <VideoModal
      :modalbg="modalbg"
      :showModal="showModal"
      videoclass="section3__videoModal"
      @onClose="showModal = false"
      v-bind:value.sync="showModal"
    >
      <template #video>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Y2Gy6fPXpWU?controls=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </template>
    </VideoModal>
  </div>
</template>

<script>
import VideoModal from "@/components/common/VideoModal.vue";
export default {
  components: {
    VideoModal,
  },
  data() {
    return {
      showModal: false,
      modalbg: require("../../assets/images/benefits/video-bg.webp"),
      whitebottle: require("../../assets/images/benefits/whitebottle.png"),
      bottle: this.$i18n.locale === "ar"
          ? require("../../assets/images/benefits/bottle_ar.png")
          : require("../../assets/images/home/section1/bottle.svg"),
      play: require("../../assets/images/home/common/play.svg"),
    };
  },
  methods: {
    onClick(e) {
      this.showModal = true;
    },
    onClickOutside(e) {
      let s3videobtn = document.querySelector(".benefits__section3__playbtn");
      if (
        !s3videobtn.contains(e.target) &&
        this.showModal &&
        e.target !== s3videobtn
      ) {
        this.showModal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
