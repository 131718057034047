<template>
  <div class="benefits">
    <AppHeader />
    <full-page ref="fullpage3" :options="options" id="fullpage3">
      <Section1
        class="section "
        :class="{ 'fp-auto-height': showsection1content }"
      />

      <!-- <Section2 class="section" /> -->
      <Section3 class="section" id="BenefitSection2" />

      <AppFooter
        @onClick="moveup"
        class="section fp-auto-height footer-section benefits-footer"
        :class="locale"
      />
    </full-page>
    <Waves />
    <CircleMenu />
  </div>
</template>

<script>
import Section1 from "@/components/benefits/section1.vue";

import Section3 from "@/components/benefits/section3.vue";

import AppHeader from "@/components/Header.vue";
import AppFooter from "../components/footer/Footer.vue";
import Waves from "@/components/general/Waves.vue";
import CircleMenu from "@/components/general/Circles.vue";

export default {
  data() {
    return {
      modalbg: require("../assets/images/home/common/modal-bg.png"),

      options: {
        licenseKey: "BD497841-800F4E51-B6A2DCEB-DE65B139",
        scrollingSpeed: 1000,
        scrollBar: true,
        responsiveWidth: 767,
      },
    };
  },
  components: {
    Section1,

    Section3,

    AppHeader,
    AppFooter,
    Waves,
    CircleMenu,
  },
  computed: {
    showsection1content() {
      return window.matchMedia("(max-width: 767px)").matches;
    },
    locale() {
      this.$i18n.locale;
    },
  },
  mounted() {
    if (this.$route.hash) {
      this.$scrollTo(this.$route.hash, 500);
    }
  },
  methods: {
    moveup() {
      this.$scrollTo("#BenefitSection1");
    },
  },
};
</script>

<style lang="scss" scoped></style>
