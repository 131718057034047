<template>
  <section
    class="benefits__section1 position-relative w-100"
    id="BenefitSection1"
    :class="locale"
    @click="onClickOutside"
  >
    <div
      class="row  flex-column-reverse   benefits__section1__row"
      :class="[locale == 'ar' ? 'flex-lg-row-reverse' : 'flex-lg-row']"
    >
      <div class="col-lg-6 col-12 benefits__section1__textCol" :class="locale">
        <div class=" benefits__section1__textContentContainer" :class="locale">
          <div
            class="d-flex align-items-center "
            :class="locale == 'fr' ? 'pl-5' : ''"
          >
            <TextContent
              :heading="$t('benefits.heading')"
              :text1="$t('benefits.text')"
              :headingclass="`benefits__heading ${locale}`"
              :text1class="`benefits__text ${locale}`"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-12 ">
        <VideoSection
          v-bind:value.sync="showModal"
          :showModal="showModal"
          @OnShowModal="showModal = true"
        />
      </div>
    </div>
  </section>
</template>

<script>
import TextContent from "@/components/benefits/TextContent.vue";
import VideoSection from "./section1Video.vue";
export default {
  components: {
    TextContent,
    VideoSection,
  },
  data() {
    return {
      showModal: false,
      whitebottle: require("../../assets/images/benefits/whitebottle.png"),
      bottle: require("../../assets/images/benefits/bottle_ar.png"),
      // bottle: require("../../assets/images/benefits/bottle.svg"),
      heading:
        "Sidi harazem aide le corps à  <span class='text-secondary'>récupérer</span> <br> l’eau et les minéraux  perdus lors de l’effort  physique,<br> intellectuel,  et lors de périodes  de chaleur. ",
      text1:
        "  Très appréciée des athlètes en raison de cette composition particulière et équilibrée ,<br>  l’eau Sidi Harazem entretient également une longue histoire avec le sport. Depuis plusieurs années ,<br>  elle a soutenu, et continue de soutenir, entre autres, l’Association Femmes, Réalisations et Valeurs ,<br> présidée par la grande athlète Nezha Bidouane. Double championne du monde du 400 m haies et  <br> médaillée de bronze aux JO de Sydney, Nezha Bidouane est l’athlète marocaine et arabe  <br> la plus titrée de l’histoire",
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    onClickOutside(e) {
      let s2videobtn = document.querySelector(".benefits__section2__playbtn");
      if (
        !s2videobtn.contains(e.target) &&
        this.showModal &&
        e.target !== s2videobtn
      ) {
        this.showModal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
