<template>
  <div
    class="commonsection__videoSection__modalContainer"
    :class="[{ show: showModal }, videoclass]"
    v-click-outside="onClickOutside"
  >
    <slot name="video">
      <div
        class="commonsection__videoSection__modalContainer__modal"
        :style="{ backgroundImage: `url(${modalbg})` }"
      ></div>

      <div class="commonsection__videoSection__modalContainer__logo">
        <img :src="logo" alt="logo" />
      </div>
      <button
        @click="$emit('onClose')"
        class="btn commonsection__videoSection__modalContainer__close"
      >
        <img :src="cross" alt="close modal" />
      </button>

      <button class="btn commonsection__videoSection__playbtn">
        <img :src="play" alt="play button" />
      </button>
      <!-- slot for video -->
    </slot>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  props: ["modalbg", "showModal", "videoclass"],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      cross: require("../../assets/images/home/common/cross.webp"),
      play: require("../../assets/images/home/common/play.svg"),
      logo: require("../../assets/images/home/initial/logo-red.webp"),
    };
  },
  methods: {
    onClickOutside(e) {
      if (document.querySelector(".section2__videobtn")) {
        let s2videobtn = document.querySelector(".section2__videobtn");

        if (
          !s2videobtn.contains(e.target) &&
          this.showModal &&
          e.target !== s2videobtn
        ) {
          this.$emit("onClose");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
