<template>
  <div class="benefits__textContent" data-aos="fade-up" data-aos-delay="100">
    <div class="benefits__headingContainer">
      <h1 :class="headingclass" v-html="heading"></h1>
    </div>

    <p :class="text1class" v-html="text1"></p>
    <p v-if="text2" :class="text2class" v-html="text2"></p>
  </div>
</template>

<script>
export default {
  props: [
    "heading",
    "text1",
    "text2",

    "headingclass",
    "text1class",
    "text2class",
  ],
};
</script>

<style lang="scss" scoped></style>
