<template>
  <div class="benefits__section2__video">
    <button
      class="btn benefits__section2__playbtn"
      @click="$emit('OnShowModal')"
    >
      <img class="img-fluid" :src="play" alt="play button" />
      <p class="benefits__section2__playbtn__text">
        {{ $t("benefits.videotext") }}
      </p>
    </button>
    <VideoModal
      :modalbg="modalbg"
      :showModal="showModal"
      videoclass="section2__videoModal"
      @onClose="showModal = false"
      v-bind:value.sync="showModal"
    >
      <template #video>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/ccRiV_jJO7A"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </template>
    </VideoModal>
  </div>
</template>

<script>
import VideoModal from "@/components/common/VideoModal.vue";
export default {
  props: ["showModal"],
  components: {
    VideoModal,
  },
  data() {
    return {
      modalbg: require("../../assets/images/benefits/video-bg.webp"),
      whitebottle: require("../../assets/images/benefits/whitebottle.png"),
      bottle:
        this.$i18n.locale === "ar"
          ? require("../../assets/images/benefits/bottle_ar.png")
          : require("../../assets/images/home/section1/bottle.svg"),
      play: require("../../assets/images/home/common/play.svg"),
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
